import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import { AuthContext } from "../../../context/AuthContext";
import tableStepImg from "../../../media/v3_tableStep.png";
import {
  DAPP_SERVER_ENDPOINT,
  SERVER_ENDPOINT,
} from "../../../utils/constants";
import { formatNumberWithCommas } from "../../../utils/formatedNumberWithComas";
import "./airDrop.css";

const AirDropTableStep = () => {
  const { user } = useContext(UserContext);
  const [loadding, setLoading] = useState(false);
  const [airDropinformation, setAirdropInformation] = useState(null);
  const [frgbPrice, setFrgbPrice] = useState(null);
  const navigate = useNavigate();
  const { jwt } = useContext(AuthContext);
  useEffect(() => {
    if (!jwt) {
      navigate("/");
    }
  }, [jwt]);
  useEffect(() => {
    console.log(user);
    async function fetchAirdropInfo() {
      setLoading(true);
      try {
        const response = await fetch(
          // user?.userID
          // 7961360169
          `${SERVER_ENDPOINT}/v1/user/getAirDropUserTokens/${user?.userID}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const airDropInfo = await response.json();
        if (airDropInfo.status === "SUCCESS") {
          setAirdropInformation(airDropInfo.data);
          setLoading(false);
        } else {
          setLoading(false);
          throw new Error("Failed to fetch referred users");
        }
      } catch (error) {
        setLoading(false);
      }
    }
    async function fetchArgbPrice() {
      setLoading(true);
      try {
        const response = await fetch(`${DAPP_SERVER_ENDPOINT}/frgb-data`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const argbPriceInfo = await response.json();
        // console.log("frgb price", argbPriceInfo);
        setFrgbPrice(argbPriceInfo?.priceUsd);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    fetchArgbPrice();
    fetchAirdropInfo();
  }, [user]);

  return (
    <div className="airDropMainContainer">
      <div
        style={{
          position: "absolute",
          height: "130px",
          width: "100%",
          background:
            "linear-gradient(to top, rgba(0, 0, 0, 0.90) 20%, rgba(0, 0, 0, 0) 100%)",
          zIndex: 1,
          top: "30%",
          pointerEvents: "none",
        }}
      ></div>
      <div className="airDropImg_section">
        <div className="airDropCloseIcon">
          <span onClick={() => navigate("/?noload=true")}>CLOSE X</span>
        </div>
        <img src={tableStepImg} alt="" className="airDropTopImg" />
      </div>

      <div
        className="airDrop_Text_section"
        style={{
          zIndex: 101010,
          position: "absolute",
        }}
      >
        <div>
          <span
            onClick={() => navigate("/airdrop/learnMore")}
            style={{
              position: "absolute",
              fontSize: "25px",
              color: "black",
              background: "white",
              textAlign: "center",
              height: "30px",
              width: "30px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: "-35%",
              right: "3%",
              zIndex: 101010,
              border: "0.5px solid black",
            }}
          >
            ?
          </span>
          <button
            style={{
              background: "white",
              color: "black",
              fontWeight: "700",
              fontSize: "15px",
              padding: "5px 10px",
              outline: "none",
              border: "none",
              borderRadius: "20px",
              textAlign: "center",
              width: "200px",
            }}
          >
            YOUR AIRDROP
          </button>
          <div
            style={{
              textAlign: "center",
              marginTop: "5px",
            }}
          >
            <span
              style={{
                color: "white",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Follow your Airdrop
            </span>
          </div>
        </div>
      </div>

      <div
        style={{
          margin: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            background: "rgba(42,99,244,1)",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            padding: "15px",
            fontWeight: 500,
          }}
        >
          <div>Amount Vested</div>
          <div>Amount Received</div>
          <div>$FRGB Price</div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            background: "white",
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
            padding: "5px",
            fontWeight: 500,
            color: "black",
            height: "40px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              fontSize: "14px",
              width: "150px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {formatNumberWithCommas(
              Number(airDropinformation?.totaltokenAmount).toFixed(2)
            )}
          </div>
          <div
            style={{
              padding: "1px",
              background: "blue",
              height: "100%",
            }}
          ></div>
          <div
            style={{
              textAlign: "center",
              fontSize: "14px",
              width: "150px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {formatNumberWithCommas(
              Number(airDropinformation?.totalDepositedTokens).toFixed(2)
            )}
          </div>
          <div
            style={{
              padding: "1px",
              background: "blue",
              height: "100%",
            }}
          ></div>
          <div
            style={{
              textAlign: "center",
              width: "110px", // Keep a fixed width
              overflow: "hidden", // Prevent overflow
              textOverflow: "ellipsis", // Show ellipsis for overflowing text
              whiteSpace: "nowrap", // Prevent text from wrapping
            }}
          >
            {/* $ {Number(frgbPrice).toFixed(8)} */}
            NA
          </div>
        </div>
      </div>
      <div
        style={{
          height: "40vh",
          overflowY: "scroll",
          background: "#D9D9D9",
          borderRadius: "10px",
          margin: "3px",
        }}
      >
        <div
          style={{
            position: "sticky",
            top: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            textAlign: "center",
            fontWeight: "normal",
            padding: "10px",
            background: "#ddd",
            zIndex: 1,
            color: "black",
          }}
        >
          <span>Airdrop</span>
          <span
            style={{
              textAlign: "left",
            }}
          >
            $FRGB Amount
          </span>
          <span>Date</span>
          <span>Status</span>
        </div>
        {Array.from({ length: 10 }).map((_, index) => {
          const frgbAmount = airDropinformation?.totaltokenAmount
            ? (Number(airDropinformation.totaltokenAmount) * 0.1).toFixed(2)
            : "0.00";

          const startDate = new Date("2024-12-26");
          startDate.setDate(startDate.getDate() + index * 14);
          const formattedDate = startDate.toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });

          return (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                textAlign: "center",
                padding: "10px",
                background:
                  index < airDropinformation?.depositCount
                    ? "#CDF2BC"
                    : "#BDBEBD94",
                borderBottom: "4px solid white",
                color: "black",
              }}
            >
              <span
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  color: "blue",
                  background: "white",
                  fontWeight: "bold",
                  padding: "2px",
                  textAlign: "center",
                }}
              >
                {index + 1}
              </span>
              <span
                style={{
                  marginLeft: "45px",
                }}
              >
                {formatNumberWithCommas(frgbAmount)}
              </span>
              <span>{formattedDate}</span>
              <span
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  background:
                    index < airDropinformation?.depositCount
                      ? "#59d62e"
                      : "gray",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                ✓
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AirDropTableStep;
